body {
    margin: auto;
  }
  h1, h2, h3, h4, h5, p, ul, li {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  a {
    text-decoration: none;
    color: rgb(124, 124, 124);
  }

  a:hover {
    color: #000;
    transition-duration: 0.6s;
  }
  
  img {
    /* margin-top: 40px; */
    /* margin-bottom: 40px; */
    /* width: 900px; */
    /* max-width: 900px; */
  }

  .img-900 {
    max-width: 900px;
  }

  #img-logo {
    width: 700px;
  }
  
  h2 {
    font-size: 20px;
    font-weight: 800px;
  }

  h3 {
    margin-top: 2em;
    font-size: 20px;
    font-weight: 400px;
    color: rgb(80, 80, 80);
  }

  h4 {
    font-size: 20px;
    font-weight: 100px;
  }

  h5 {
    font-size: 12px;
    color: rgb(54, 54, 54);
  }

  h1 {
    font-size: 2.6em;
    font-weight: 300;
    width: 800px;
    color: rgb(46, 46, 46);
    margin-left: auto;
    margin-right: auto;
  }
  .content {
    background-color: #FFF;
    /* padding: 10px; */
    margin-left: auto;
    margin-right: auto;
  }
  .content h2 {
    padding: 0;
    margin: 0;
  }
  .content li {
    margin-bottom: 10px;
  }
  .active {
    color: rgb(0, 0, 0);
  }
  #main {
    display: flex;
    flex-direction: column;
    /* margin-left: auto;
    margin-right: auto;
    margin-top: 10px; */
  }
  #container {
      /* position: relative; */
      display: flex;
      flex-wrap: wrap;
      width: 800px;
      /* height: 700px; */
      /* background-color: black; */
      margin-left: auto;
      margin-right: auto;
      padding: 10px;
  }

  .el {
    width: 380px;
    height: 380px;
    margin: 10px;
    background-color: rgb(0, 0, 0);
    transition: .5s ease;
    
  }

  .el_01 {
    width: 780px;
    height: 248px;
    margin: 10px;
    background-color: rgb(0, 0, 0);
    transition: .5s ease;
    
  }


  /* .sub_el {
    width: 380px;
    height: 380px;
    margin: 10px;
    background-color: rgb(0, 0, 0);
    display: none;

  } */

  .el:hover {
    opacity: 0.8;
    /* display:block; */
  }

  .el_01:hover {
    opacity: 0.8;
    /* display:block; */
  }

  .menu {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    float: right;
    margin-top: 20px;
    font-weight: 700;
    font-size: 0.9em;
    word-spacing: 2em;    
  }

  /* .menu a {
    color: rgb(206, 7, 7);
    word-spacing: 2em;
  } */

  .logo {
    display: inline-block;
    letter-spacing: -0.8px;
    color: rgb(46, 46, 46);
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }

  .header {
    
    width: 900px;
    display: inline-block; 
    margin: auto;
  }

  #up_margin {
    height: 30px;
  }

  #down_margin {
    height: 100px;
  }

p {
  color: rgb(43, 43, 43);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 0.9rem;
  margin-top: auto;
  margin-bottom: auto;
  /* margin: auto; */
  /* line-height: 1.3rem; */
}

#nf {
  background: url('./img/nf_pres.png') no-repeat;
  background-size: 380px 380px;
}

#spbtv {
  background: url('./img/spbtv_pres.png') no-repeat;
  background-size: 380px 380px;
}

#amediateka {
  background: url('./img/amediateka_pres.png') no-repeat;
  background-size: 380px 380px;
}

#skemio {
  background: url('./img/skemio_pres.png') no-repeat;
  background-size: 380px 380px;
}

#wispi {
  background: url('./img/wispi_pres.gif') no-repeat;
  background-size: 380px 380px;
}

#logo {
  background: url('./img/logo_pres.png') no-repeat;
  background-size: 380px 380px;
}

#moba {
  background: url('./img/moba.png') no-repeat;
  background-size: 780px 248px;
}

#unselect {
  margin-top: 40px;
  margin-bottom: 40px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

#me {
  display: table-cell;
  background: url('./img/me.png') no-repeat;
  background-size: 250px 311px;
  width: 250px;
  height: 311px;
  padding: 20px;
}

#table {
  display: table;
}

#table a {
  display: inline-block;
  
}

#cell {
  display: table-cell;
  padding: 20px;
}

#button_left {
  display: table-cell;
  border-radius: 20px;
  border: 1px solid rgb(41, 41, 41);
  /* width: 100px; */
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  line-height: 38px;
  color: #000;
  font-weight: 500;
  font-size: 14px;
}

#button_left:hover {
  border-radius: 20px;
  background: rgb(41, 41, 41);
  color: #fff;

}

#linkedin {
  display: table-cell;
  background: url('./img/linkedin.png') no-repeat;
  background-size: 40px 40px;
  height: 40px;
  width: 40px;
}

#info {
  padding: 0.5em;
  margin: 2em;
  background-color: rgb(255, 249, 217);
}

.a_color {
  color: #000;
  font-weight: 600;
  text-decoration: underline;
}

.a_tab {
  margin-right: 20px;
}

.indent {
  /* text-indent: -1em; */
  margin-top: 0em;
  margin-bottom: 0em;

}

ul {
  list-style-type: square; /* Маркеры в виде квадрата */
  list-style-position: outside;
  list-style-type: none;
  padding: 0;
}

ol {
  /* margin: 0;  */
  padding-top: 0.5em;
  margin-top: 0em;
  margin-bottom: 0em;
  line-height: 0.9em;
}
/* 
li {
  margin: 0;
} */

#m_h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 68px;
  text-align: left;
  
}

#m_h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: left;
}

#m_h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: left;
}

#m_h4 {
  font-family: Roboto;
  font-style: italic;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #DD00C7;
}

#m_italic {
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
}

#m_body {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}

#m_body em {
  color: #DD00C7;
  font-weight: bold;
  font-style: normal;
}

#m_block {
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
  /* margin-bottom: 40px; */
  /* background: #ddd; */
    
}

#m_block_01 {
  display: flex;
  /* gap: 40px; */
  /* margin-bottom: 40px; */
}

.padding-40 {
  padding-block: 50px;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

.gap-20 {
  gap: 20px;
}

.gap-40 {
  gap: 40px;
}

#m_divider {
  height: 40px;
}

.m_i_600 {
  width: 600px;
}

.m_i_480 {
  width: 480px;
}

.m_i_180 {
  margin-left: 180px;
}

.m_i_600_v {
  width: 600px;
  flex-direction: column;
}



.bg-blue {
  /* color: tomato; */
  /* width: 100%; */
  width: 100vw;
  background: #151136;
}


#default-width {
  width: 900px;
  margin: auto;
}
